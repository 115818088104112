<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard class="clapans">
          <CCardHeader>
            <div class="row">
              <div class="col-md-6 d-flex justify-content-end">

              </div>
              <div class="col-md-6 d-flex justify-content-end align-items-center">
                <span class="font-lg alert-success mr-4">
                  {{ all_sum }}

                  Общий долг: <b>{{
                    all_sum
                  }} - {{ all_sum_wallet }} = {{ all_sum - all_sum_wallet }}</b></span>
                <CButton color="success" @click="openModal()">
                  Внести оплату
                </CButton>
              </div>
            </div>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                class="mb-0 table-outline"
                hover
                :items="tableItems"
                :fields="tableFields"
                head-color="light"
                column-filter
                table-filter
                sorter
                :tableFilter="{'label':'Поиск','placeholder':'Введите слово для поиска'}"
            >
              <td slot="number_of_birca" slot-scope="{ item,index }">{{ filterNull(item.number_of_birca, item) }}</td>
              <td slot="weight" slot-scope="{ item,index }">{{ filterNull(item.weight, item) }}</td>
              <td slot="code_of_client" slot-scope="{ item,index }">{{ filterNull(item.code_of_client, item) }}</td>
              <td slot="name_of_client" slot-scope="{ item,index }">{{ filterNull(item.name_of_client, item) }}</td>
              <td slot="naim" slot-scope="{ item,index }">{{ filterNull(item.naim, item) }}</td>
              <td slot="upakovka" slot-scope="{ item,index }">{{ filterNull(item.upakovka, item) }}</td>
              <td slot="address" slot-scope="{ item,index }">{{ filterNull(item.address, item) }}</td>
              <td slot="type_of_race" slot-scope="{ item,index }">{{ filterNull(item.type_of_race, item) }}</td>
              <td slot="race_number" slot-scope="{ item,index }">{{ filterNull(item.race_number, item) }}</td>
              <td slot="price_per_kg" slot-scope="{ item,index }">{{ filterNull(item.price_per_kg, item) }}</td>
              <td slot="price_per_place" slot-scope="{ item,index }">{{ filterNull(item.price_per_place, item) }}</td>


              <td slot="date_of_traffic" slot-scope="{ item,index }">
                {{ distanceFromNow(item.date_of_traffic) }}
              </td>
              <td slot="summa" slot-scope="{ item,index }">
                {{ item.action == 'oplata' ? '' : item.summa }}
              </td>
              <td slot="oplata" slot-scope="{ item,index }">
                {{ item.action == 'oplata' ? '- ' + item.summa : '' }}
              </td>
              <td slot="action" slot-scope="{ item,index }" class="d-flex">
                <template v-if="item.action!='oplata'">
                  <CButton color="danger" @click="deleteItem(item,index)" class="mr-2">
                    Удалить
                  </CButton>

                  <CButton color="success" @click="viewItem(item,index)">
                    Посмотреть
                  </CButton>
                </template>
                <template v-else="">
                  <CButton color="danger" @click="deletePay(item,index)" class="mr-2">
                    Удалить
                  </CButton>
                  <CButton color="success" @click="updatePay(item,index)">
                    Изменить
                  </CButton>

                </template>

              </td>
            </CDataTable>

            <div class="row">
              <div class="col-md-6">
              </div>
              <div class="col-md-6">
                Количество мест: {{ tableItems.length }} |
                Оплата: {{ all_sum_wallet }}
              </div>
            </div>
          </CCardBody>
        </CCard>
      </CCol>

    </CRow>
    <CModal title="Внесение оплаты" :show.sync="historyModal">
      <CCard>
        <CCardBody>
          <CForm v-on:submit="submitForm()">
            <CInput type="hidden" v-model="history.id" horizontal/>
            <CInput type="text" v-model="history.value" label="Значение" horizontal
            />
            <CButton color="success" type="submit">
              Сохранить
            </CButton>
          </CForm>
        </CCardBody>
      </CCard>
    </CModal>
    <div class="alert_block">
      <CAlert color="warning" closeButton v-if="warning">
        {{ alert_message }}
      </CAlert>
      <CAlert color="primary" closeButton v-if="success">
        {{ alert_message }}
      </CAlert>
    </div>
  </div>
</template>

<script>
const moment = require('moment');
const qs = require('qs')
const axios = require('axios')
export default {
  name: "MasterHubs",
  data() {
    return {
      history: [],
      history_list: [],
      historyModal: false,
      all_sum: 0,
      all_sum_wallet: 0,
      warning: false,
      success: false,
      warningModal: false,
      isSelect: false,
      action_switcher: '',
      action_switcher_city: '',
      id: "",
      file_path: '',
      fileData: '',
      tableItems: [],
      alert_message: '',
      tableFields: [
        {key: "date_of_traffic", label: "Дата"},
        {key: "number_of_birca", label: "БРК "},
        {key: "weight", label: "Вес", _classes: "text-center"},
        {key: "code_of_client", label: "Код"},
        {key: "name_of_client", label: "ФИО"},
        {key: "naim", label: "Наим"},
        // {key: "otpravka", label: "Отправка"},
        {key: "upakovka", label: "УПК"},
        // {key: "sborn", label: "Сборн"},
        // {key: "address", label: "Адрес"},
        {key: "type_of_race", label: "Тип рейса "},
        {key: "race_number", label: "Номер рейса"},
        {key: "summa", label: "Сумма долга"},
        {key: "oplata", label: "Оплата"},
        {key: "price_per_kg", label: "цена за кг"},
        {key: "price_per_place", label: "цена за место"},
        {key: "action", label: "Действия"},
      ],
      tableFieldsWallet: [
        {key: "created_at", label: "Дата"},
        {key: "status", label: "Статус",},
        {key: "value", label: "Сумма"},
      ]
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    filterNull(item) {
      if (item == null || item == '') {
        return '';
      } else {
        return item;
      }
    },
    deletePay(item, index) {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/history-wallets/delete/' + item.id).then(response => {
        app.getResults();
      }).catch(error => {
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });
    },
    updatePay(item) {
      this.historyModal = !this.historyModal;
      this.history.id = item.id;
      this.history.value = item.summa;
    },
    distanceFromNow(date_of_traffic) {
      if (date_of_traffic != null) {
        let date = date_of_traffic.split('/');
        let new_date = date[2] + '.' + date[1] + '.' + date[0];
        return new_date;
      } else {
        return date_of_traffic
      }
    },
    dateReverse(value) {
      let date = value.split('.');
      let new_date = date[2] + '/' + date[1] + '/' + date[0];
      return new_date;
    },
    openModal() {
      this.historyModal = !this.historyModal;
      this.history.id = 0;
      this.history.value = '';
      this.history.status = 0;
    },
    submitForm() {
      event.preventDefault();
      let app = this;
      this.history.client_id = this.$route.params.id;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios(
          {
            method: 'post',
            url: '/auth/history-wallets/add',
            data: qs.stringify(this.history),
          })
          .then(({data}) => {
            app.getResults();
            app.historyModal = false;
            app.success = true;
            app.alert_message = 'Успешно сохранено';
            app.setting = {};
            setTimeout(function () {
              app.success = false;
            }, 3000)
          }).catch(function (error) {
        app.warning = true;
        app.alert_message = error.response.data.detail;
        setTimeout(function () {
          app.warning = false;
        }, 3000)

      })
    },
    deleteAll() {
      if (confirm("Вы действительно хотите удалить все данные из базы?")) {
        let app = this;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
        axios.get('/auth/traffics/delete-all').then(response => {
          app.tableItems = [];
        }).catch(error => {
          if (error.response.status == 401) {
            window.auth.logout();
          }
        });
      } else {
        console.log("test");
      }
    },
    createNew() {
      this.$router.push('/clients/0');
    },
    viewItem(item, index) {
      let app = this;
      this.$router.push('/clients/item/' + item.id);
    },
    deleteItem(item, index) {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/traffics/delete/' + item.id).then(response => {
        app.tableItems.splice(index, 1);
      }).catch(error => {
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });
    },
    deleteAll() {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/traffics/delete-all').then(response => {
        app.tableItems = [];
      }).catch(error => {
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });
    },
    getResults() {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/traffics/get?id=' + this.$route.params.id).then(response => {
        app.tableItems = response.data.traffics;
        app.history_wallets = response.data.history_wallets;
        app.all_sum = 0;
        app.all_sum_wallet = 0;
        for (var i = 0; i < app.tableItems.length; i++) {
          app.tableItems[i].action = true;
          app.tableItems[i].date_of_traffic = this.dateReverse(app.tableItems[i]['date_of_traffic'])
          // app.tableItems[i].date_of_traffic = moment(app.tableItems[i]['date_of_traffic']).format('Y/MM/DD');
          app.all_sum += app.tableItems[i].summa
        }

        for (var j = 0; j < app.history_wallets.length; j++) {
          let now_arr = {
            'date_of_traffic': '',
            'number_of_birca': '',
            'weight': '',
            'code_of_client': '',
            'name_of_client': '',
            'naim': '',
            'upakovka': '',
            'type_of_race': '',
            'race_number': '',
            'summa': '',
            'price_per_kg': '',
            'action': 'oplata',
            'id': '',
          };
          // now_arr['date_of_traffic'] = moment(app.history_wallets[j]['created_at']).format('Y/MM/DD');
          now_arr['date_of_traffic'] = this.dateReverse(app.history_wallets[j]['created_at'])
          now_arr['summa'] = app.history_wallets[j]['value'];
          now_arr['id'] = app.history_wallets[j]['id'];
          app.tableItems.push(now_arr);
          app.all_sum_wallet += parseInt(app.history_wallets[j].value)
        }

      }).catch(error => {
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });
    },

  },
};
</script>
<style>
.clapans .form-inline {
  margin-left: 15px;
}

.alert_block {
  position: fixed;
  right: 0;
  bottom: 0;
}
</style>

